const response = [
    {
        "name": "Google Forms",
        "info": "Free with Google account.",
        "url": "https://www.google.com/forms",
        "image": "./google-form.png"
    },
    {
        "name": "Metaforms",
        "info": "If ChatGPT & Typeform had a kid. Not cheap exactly, but worth every $.",
        "url": "https://dub.sh/cheap",
        "image": "./metaform.png"
    },
    {
        "name": "JotForm",
        "info": "Free plan available, paid plans are generally cheaper than Typeform.",
        "url": "https://www.jotform.com/",
        "image": "./jot-form.png"
    },
    {
        "name": "SurveyMonkey",
        "info": "Offers a limited free plan; more affordable plans for basic features.",
        "url": "https://www.surveymonkey.com/",
        "image": "./survey_monkey.png"
    },
    {
        "name": "Paperform",
        "info": "Cheaper than Typeform with similar features.",
        "url": "https://paperform.co/",
        "image": "./paper-cover.png"
    },
    {
        "name": "Zoho Forms",
        "info": "Free plan available; affordable paid plans.",
        "url": "https://www.zoho.com/forms/",
        "image": "./zoho.png"
    }
];
export { response };