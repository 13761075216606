import { response } from "./data/FormList";

const MainComponent = () => {
    return (<div className='mx-auto p-3 max-w-xl space-y-4'>
        
        <div className="container my-12  flex max-w-[64rem] flex-col items-center gap-4 text-center">
            <h1 className=" text-3xl font-semibold sm:text-5xl md:text-6xl lg:text-7xl">
                Cheaper TypeForm Alternatives
            </h1>
            <p className="max-w-[42rem] leading-normal text-muted-foreground sm:text-xl sm:leading-8">
                Why waste a buck when better is cheaper?
            </p>
            </div>
        {response.map((item, index) => (<a href={item.url} class="block  p-4 bg-white border border-gray-200 rounded-lg space-x-6 shadow hover:bg-gray-100 flex flex-row">
            <img className='h-[90px] object-cover w-[90px] rounded-xl ' src={item.image} />
            <div>
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">{item.name}</h5>
                <p class="font-normal">{item.info}</p>
            </div>
        </a>))
        }
    </div>);
}
export default MainComponent;