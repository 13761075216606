import logo from './logo.svg';
import './App.css';
import MainComponent from './Main';
import { Toaster, toast } from 'sonner'
import { useEffect } from 'react';
import { response as FormList } from './data/FormList';
function App() {

  function getRandomNumber() {
    const randomNumber = Math.floor(Math.random() * 11); // Generate random number between 0 and 10
    return randomNumber <= 5 ? randomNumber : 1; // If randomNumber is between 0 and 5, return it, otherwise return 1
  }
  useEffect(() => {
    setInterval(() => {
      toast(`Someone clicked on ${FormList[getRandomNumber()].name}`)
    }, 2000);
  }, [])
  return (
    <>
      <a href='https://workhack.frms.ai/663b28e5e1961b1c02fd41a8' className="flex flex-col items-center justify-center rainbow-bg py-2 ">
        <div className='text-white font-bold'>
          Want to feature your form builder?
        </div>
      </a>
      <Toaster position='top-right' />
      <MainComponent />

    </>
  );
}

export default App;
